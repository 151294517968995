#gallery {
  opacity: 0;
  z-index: 201;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  font-family: Ubuntu;
  animation: .2s linear fadeIn;
  display: flex;
  position: absolute;
  inset: 0;
}

#gallery-content {
  background: #fff;
  border-radius: 15px;
  flex-direction: column;
  max-width: 45%;
  max-height: 80%;
  animation: .6s linear forwards scaleUp;
  display: flex;
  overflow: hidden;
  transform: scale(.8);
}

#gallery-header {
  color: #fff;
  text-align: center;
  background: #00b3ff;
  font-size: 26px;
}

#gallery-body {
  min-height: 0;
  display: flex;
}

#skinsUL {
  scrollbar-color: #9090a0 #fff;
  scrollbar-width: thin;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 10px 0 0 10px;
  display: flex;
  overflow: hidden auto;
}

#skinsUL::-webkit-scrollbar {
  width: 12px;
}

#skinsUL::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

#skinsUL::-webkit-scrollbar-thumb {
  background: #9090a0;
  border-radius: 6px;
}

#skinsUL::-webkit-scrollbar-thumb:hover {
  background: #708090;
}

.selected-skin-container {
  text-align: center;
  margin-left: 40px;
}

.skin {
  margin-bottom: 10px;
  margin-right: 10px;
  list-style-type: none;
}

.circular {
  cursor: pointer;
  background: url("checkerboard.6f5c998f.png") 0 0 / 150px 150px no-repeat;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  transition: all .2s;
  box-shadow: 0 0 8px #000c;
}

.skinName {
  text-align: center;
  margin-bottom: 0;
  transition: all .2s;
}

.circular:hover {
  box-shadow: 0 0 12px #000c;
}

.circular:hover + .skinName {
  text-shadow: 0 0 1px #000c;
}

.coin-info {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.coin-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

#coinCount {
  font-size: 16px;
  font-weight: bold;
}

.remove-skin-btn {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: red;
  border-radius: 5px;
  width: 120px;
  height: 30px;
  margin: 10px auto 0;
  line-height: 30px;
  display: block;
}

.remove-skin-btn:hover {
  background-color: #8b0000;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(.8);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

.selected-skin-animation {
  animation: 2s infinite pulse;
}
/*# sourceMappingURL=index.84bc68bd.css.map */
