#gallery {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 201;
    display: flex;
    justify-content: center;
    align-items: center;
	font-family: 'Ubuntu';
	animation: fadeIn 0.2s linear;
}
#gallery-content {
    background: #fff;
    max-height: 80%;
    max-width: 45%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
	animation: scaleUp 0.6s forwards linear;
    transform: scale(0.8);
}
#gallery-header {
    font-size: 26px;
    background: rgb(0, 179, 255);
    color: #fff;
    text-align: center;
}
#gallery-body {
    display: flex;
    min-height: 0;
}
#skinsUL {
    padding: 0;
    margin: 0;
    padding-left: 10px;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    scrollbar-color: #9090a0 #fff;
    scrollbar-width: thin;
}
#skinsUL::-webkit-scrollbar {
    width: 12px;
}
#skinsUL::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 6px;
}
#skinsUL::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #9090a0;
}
#skinsUL::-webkit-scrollbar-thumb:hover {
    background: slategrey;
}
.selected-skin-container {
    text-align: center;
	margin-left: 40px;
}
.skin {
    margin-right: 10px;
    margin-bottom: 10px;
    list-style-type: none; /* removes bullet point */
}
.circular {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: url("../static/img/checkerboard.png");
    background-repeat: no-repeat;
    background-size: 150px 150px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .8);
    cursor: pointer;
    transition: all 0.2s;
}
.skinName {
    text-align: center;
    margin-bottom: 0;
    transition: all 0.2s;
}
.circular:hover {
    box-shadow: 0 0 12px rgba(0, 0, 0, .8);

}
.circular:hover + .skinName {
    text-shadow: 0 0 1px rgba(0, 0, 0, .8);
}
.coin-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.coin-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
#coinCount {
    font-size: 16px;
    font-weight: bold;
}
.remove-skin-btn {
    display: block;
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: red;
    color: white;
    border-radius: 5px;
    margin: 10px auto 0;
    cursor: pointer;
}

.remove-skin-btn:hover {
    background-color: darkred;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scaleUp {
  from {transform: scale(0);} 
  to {transform: scale(0.8);}
}
@keyframes scaleDown {
  from {transform: scale(1);} 
  to {transform: scale(0);}
}

.selected-skin-animation {
    animation: pulse 2s infinite;
}